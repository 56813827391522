<template>
  <div id="app" :style="$store.state.mode == 'player' && { '--userColor': '#' + $store.state.user.profile.color }">
    <!-- Este template hostea tanto la interfaz mobil como las screens -->
    <b-loading v-model="$store.state.loading">
      <div class="loading-screen fullHeight">
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90C69.8528 90 90 69.8528 90 45ZM13.5 45C13.5 27.603 27.603 13.5 45 13.5C62.397 13.5 76.5 27.603 76.5 45C76.5 62.397 62.397 76.5 45 76.5C27.603 76.5 13.5 62.397 13.5 45Z"
            fill="var(--loading-fill)" />
          <path class="primary"
            d="M90 45C90 50.9095 88.836 56.7611 86.5746 62.2208C84.3131 67.6804 80.9984 72.6412 76.8198 76.8198C72.6412 80.9984 67.6804 84.3131 62.2208 86.5746C56.7611 88.836 50.9095 90 45 90L45 76.5C49.1366 76.5 53.2328 75.6852 57.0545 74.1022C60.8763 72.5192 64.3488 70.1989 67.2739 67.2739C70.1989 64.3488 72.5192 60.8763 74.1022 57.0545C75.6852 53.2328 76.5 49.1366 76.5 45H90Z"
            fill="var(--loading-main)" />
        </svg>
      </div>
    </b-loading>

    <template v-if="$store.state.mode == 'player' &&
    $store.state.setupLoaded &&
    validRouteForHeader
    ">
      <user-header/>

      <profile-editor v-if="$store.state.user.profile.id" :key="$store.state.user.profile.email" />
      <profile-verify v-if="$store.state.user.profile.id" />
    </template>

    <transition name="route" mode="out-in">
      <router-view v-if="$store.state.user || $store.state.space" id="room" :class="roomClass()"></router-view>
    </transition>
        <!-- FOOTER -->
        <footer
          v-if="$store.state.mode == 'player' && !$store.state.loading"
          class="app-footer p-1 has-text-centered is-size-7"
          :style="{ borderColor: '#' + $store.state.user.profile.color }"
        >
          <template v-if="hasFooter">
            <json-rich-text :text="$store.state.setup.footer.text" />
          </template>
          <template v-else>
            © {{ $t('system.footer1') }}
            <a
              href="http://douob.online"
              class="has-text-weight-bold"
              target="_blank"
              >douob.online</a
            >
            {{ $t('system.footer2') }}
            <a
              href="http://verne.studio"
              class="has-text-weight-bold"
              target="_blank"
              >verne.studio</a
            >
          </template>
        </footer>
 <!--       </template>
     </template>  -->
    <!-- Stack buttons -->
    <div v-if="validRouteForHeader" class="buttons-stack">
      <!-- Reactions -->
      <div v-if="showReactions" id="reactionButtons" :class="{ open: reactions.expanded }">
        <div v-if="!reactions.expanded" class="menu-toggle" @click="reactions.expanded = true"></div>
        <div class="menu-line">
          <div v-for="i in 5" class="btn btn-react" @touchstart="sendEmojiReaction(i - 1)"
            @touchend="releaseEmojiReaction(i - 1)" :key="`btn-react${i - 1}`"
            :class="{ sending: reactions.sending == i - 1 }">
            <img v-if="!reactions.cooldown || reactions.lastSent != i - 1"
              :src="`/assets/reactions/reaction-${i - 1}.png`" />
            <img v-else :src="`/assets/reactions/reaction-${i - 1}.webp`" />
          </div>
        </div>
      </div>
      <!-- Open Remote -->
      <div v-if="$store.getters['user/hasAdminAuthority']" id="adminButton"
        class="btn has-background-primary has-text-white-ter" @click="$store.commit('admin/showRemote', true)">
        <d-icon icon="FaGear" size="is-small" />
      </div>
      <!-- Show Talking Points -->
      <div v-if="$store.getters['user/hasAdminAuthority'] && showTalkingPotins" id="talkingPointsButton"
        class="btn has-background-white-bis has-text-primary"
        @click="$store.commit('TalkingPointsShow', room.experience)">
        <d-icon icon="FaComment" size="is-small" />
      </div>
      
      <!-- Whatsapp button -->
      <a v-if="showWhatsappBtn" :href="whatsappLink" target="_blank" id="whatsappButton" class="btn">
        <d-icon icon="FaWhatsapp" size="xs"/>
      </a>
      
      <!-- Moderator Questions -->
      <div v-if="showModeratorQuestions" id="moderatorQuestionsButton" class="btn has-background-white-bis has-text-primary"
      @click="$store.commit('event/ToggleModeratorQuestionBox')">
        <d-icon icon="FaQuestion" size="is-small" />
      </div>

    </div>
    <div v-if="reactions.expanded" class="reactions-backdrop" @click.prevent="reactions.expanded = false"
      @touchstart.prevent="reactions.expanded = false"></div>

    <!-- INVITE EXPERIENCE MODAL -->
    <b-modal v-model="ShowExperienceInviteModal" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title is-size-5">{{ $t('system.invitation') }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <template v-if="$store.state.user && $store.state.user.experienceInviteData">
            {{ $store.state.user.experienceInviteData.text }}
          </template>
        </section>
        <footer class="modal-card-foot has-background-white-bis">
          <b-button :label="$t('system.close')" type="is-primary" size="is-small" outlined expanded
            @click="$store.commit('user/ExperienceInviteHide')" />
          <b-button :label="$t('system.join')" type="is-primary" size="is-small" expanded
            @click="$store.dispatch('user/EnterExperience')" />
        </footer>
      </div>
    </b-modal>

    <!-- Admin Remote -->
    <admin-remote v-if="$store.getters['user/hasAdminAuthority'] && $store.hasModule('admin')" />
    
    <!-- Moderator Question Box -->
    <ModeratorQuestionModal v-if="showModeratorQuestions && $store.state.event?.showModeratorQuestionBox" :show="showModeratorQuestions" />

    <b-notification v-if="showConnectionError" id="connectionError" type="is-warning" has-icon icon="rotate" icon-size="is-small fa-spin" role="alert"
      :closable="false" :message="$t('notifications.connectionError')" />

    <div id="cosaspreload" style="display: none">
      <img v-for="(av, i) in $store.state.avatars" :key="i" :src="av.image.sizes.thumbnail.url" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import gsap from "gsap";
import userState from "@/store/user";
import spaceState from "@/store/space";
import adminState from "@/store/admin";
import eventState from "@/store/event";
import JsonRichText from "@/components/JsonRichText.vue";
import ModeratorQuestionModal from "./components/controllers/ModeratorQuestionModal.vue";
const whitelabelConfig = require("@/whitelabel.config.js");

export default {
  components: {
    AdminRemote: () => import("@/components/AdminRemote.vue"),
    JsonRichText,
    ModeratorQuestionModal,
  },
  props: {},
  data() {
    return {
      menuReactions: undefined,
      reactions: {
        sending: null,
        lastSent: null,
        expanded: false,
        cooldown: false,
        cooldownTimer: 1200,
      },
      footerMenuActiveTab: 0,
      loaded: false,
    };
  },
  computed: {
    showTalkingPotins() {
      if (this.$route.name == "menu") return false;
      if (!this.room?.experience.talkingPoints) return false;
      return true;
    },
    hasFooter() {
      const footerText = this.$store.state.setup.footer?.text;
      return Boolean(
        footerText && (
          footerText.length > 1 || 
          footerText[0]?.children?.[0]?.text?.trim()
        )
      );
    },
    showWhatsappBtn(){
      // Solo se muestra en el menu y si está habilitado en setup>events
      if (!this.$route.name == "menu") return false;
      return this.$store.state.setup.event?.whatsappBtn?.enabled
    },
    whatsappLink(){
      return `https://wa.me/${this.$store.state.setup.event?.whatsappBtn.phone}`
    },
    
    showModeratorQuestions(){
      if(this.$store.state.setup.event?.moderatorQuestions?.enabled){
        if(this.$route.name == "menu"){
          // Si estamos en el menu y esta habiliado showOnMenu
          return this.$store.state.setup.event?.moderatorQuestions?.showOnMenu
        }else{
          // Si estamos en un espacio y ese espacio está incluido en spacesAvailabe
          return this.$store.state.setup.event?.moderatorQuestions?.spacesAvailable?.some(space => space.slug == this.$route.params.slug)
        }
      }else{
        return false
      }
    },
    room() {
      return this.$store.getters.playlist.find(
        (x) => x.space.slug == this.$route.params.slug
      );
    },
    ShowExperienceInviteModal() {
      if (!this.$store.state.user) return false;
      if (this.$store.state.user.experienceInviteData == null) return false;
      return this.$store.state.user.experienceInviteShow || false;
    },
    validRouteForHeader() {
      return ["menu", "spaceController", "calendar", "map","booker", "photofeed","favorites"].includes(this.$route.name)
    },
    hasMobileBackground() {
      return this.$store.state.setup.mobileBackground ? true : false;
    },
    mobileBackground() {
      return this.hasMobileBackground
        ? "background-image: url(" +
        encodeURI(this.$store.state.setup.mobileBackground.url) +
        ")"
        : "";
    },
    showReactions() {
      if(this.$store.state.setup.reactions?.enabled){
        if(this.$route.name == "menu"){
          // Si estamos en el menu y esta habiliado showOnMenu
          return this.$store.state.setup.reactions?.showOnMenu
        }else{
          // Si estamos en un espacio y ese espacio está incluido en spacesAvailabe
          return this.$store.state.setup.reactions?.spacesAvailable?.some(space => space.slug == this.$route.params.slug)
        }
      }else{
        return false
      }
    },
    showConnectionError() {
      return !this.$store.state.disableReconnectingAlert &&
        this.$store.state.route.fullPath != '/spaces' &&
        this.$store.state.socketConnectedOnce &&
        !this.$socket.connected &&
        !this.$store.state.loading &&
        !this.$store.state.duplicateConnection;
    }
  },
  beforeEnter(el) {
    el.style.opacity = 0;
  },
  enter(el, done) {
    gsap.to(el, {
      opacity: 1,
      duration: 0.8,
      onComplete: done,
    });
  },
  leave(el, done) {
    gsap.to(el, {
      opacity: 0,
      duration: 0.5,
      onComplete: done,
    });
  },
  methods: {
    Reload() {
      document.location.reload();
    },
    roomClass() {
      return `route_${this.$route.name}`;
    },
    sendEmojiReaction(reaction) {
      this.reactions.sending = reaction;
      this.reactions.lastSent = reaction;

      if (this.reactions.cooldown) return;

      let color = this.$store.state.user?.profile.color;

      this.$store.dispatch('user/SendReaction', { reaction, type: 'emoji', color })

      this.reactions.cooldown = true

      setTimeout(() => {
        this.reactions.cooldown = false;
      }, this.reactions.cooldownTimer);
    },
    releaseEmojiReaction() {
      this.reactions.sending = null;
    },
  },
  sockets: {},
  mounted: async function () {
    if (whitelabelConfig.favicon) {
      const favicon = document.getElementById("favicon");
      favicon.href = whitelabelConfig.favicon;
      console.log("Changing favicon to", whitelabelConfig.favicon);
    }

    console.log("📱 DOUOB FRONTEND");
    this.$store.commit("StartLoading");
    this.$store.dispatch("LoadAvatars");
    await this.$store.dispatch("LoadSetup");
    // TODO juntar LoadAvatars y LoadSetup en un axios.all para descargar en paralelo
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios

    // const userRoutes = ["menu", "spaceController", "login", "signup"];
    // console.log("this.$route.name", this.$store.state.route)
    const screenRoutes = ["spaceScreen", "spaces", "terms", "printQR", "chroma"];
    if (screenRoutes.includes(this.$store.state.route.name)) {
      // 📺 SCREEN MODE
      console.log("📺 SCREEN MODE");
      if (!this.$store.hasModule("space")) {
        this.$store.registerModule("space", spaceState); // dynamic vuex module
        console.log("Loaded space vuex module");
        // Cache service worker. Screens only
        if ("serviceWorker" in navigator) {
          console.log("🍦👨🏻‍🚀 Registrando cache worker");
          navigator.serviceWorker.register("../CacheServiceWorker.js");
        }
      }
      this.$store.commit("SetClientMode", "screen");
    } else {

      // Player sfx load
      this.$sfxLoad(this.$store.state.setup.sounds,
        [
          'click',
          'negativenotification',
          'positivenotification',
          'invitepushtoexperience'
        ]
      );

      // 🕹 PLAYER MODE
      if (!this.$store.hasModule("user")) {
        console.log("agregando vuex user");
        this.$store.registerModule("user", userState); // dynamic vuex module
      }
      if (!this.$store.hasModule("event")) {
        console.log("agregando vuex event");
        this.$store.registerModule("event", eventState); // dynamic vuex module
      }
      
      this.$store.commit("SetClientMode", "player");
      await this.$store.commit("user/LoadPlayerEmailToken");


      if (this.$store.state.user.token) {
        await this.$store.dispatch("user/GetPlayer").catch(() => {
          this.$store.dispatch("user/GetPlayer");
        });
        if (this.$store.getters["user/hasAdminAuthority"]) {
          // 👑 ADMIN MODE Store layer
          if (!this.$store.hasModule("admin")) {
            this.$store.registerModule("admin", adminState); // dynamic vuex module
            this.$store.commit("admin/GetRawData");
          }
        }
        this.$socket.client.open();
      } else {
        // MANUAL LOGIN / REGISTER
        this.$store.commit("StopLoading");
        console.log("Usuario no encontrado. A registrarse");
        // await this.$store.commit("user/SetAccountState", 2); // register
        // console.log(this.$router, {query: { redirect: this.$route.path }})
        if (this.$route.name != "signup" && this.$route.name != "login") {
          const path = this.$route.path.replace("/", "");
          const queryParams = new URLSearchParams(this.$route.query).toString();
          const fullPath = queryParams ? `${path}?${queryParams}` : path;

          console.log("Redirecting to signup", { redirect: fullPath });
          let routeOptions = {
            path: "/signup"
          };

          if (fullPath) {
            routeOptions.query = { redirect: fullPath };
          }
          
          this.$router.replace(routeOptions);
        }
      }
    }

    // Timesync
    var Timesync = require("timesync");
    Vue.prototype.$time = Timesync.create({
      server: this.$socket.client,
      interval: 5000,
    });

    this.$time.send = function (socket, data, timeout) {
      //console.log('send', data);
      return new Promise(function (resolve, reject) {
        var timeoutFn = setTimeout(reject, timeout);
        socket.emit("timesync", data, function () {
          clearTimeout(timeoutFn);
          resolve();
        });
      });
    };

    this.$socket.client.on('timesync', (data) => {
      this.$time.receive(data.id, data);
    });

    if (this.$store.state.route.path == "/agenda") {
      this.footerMenuActiveTab = 2; // seteo el menu manualmente
    }
  },
};
</script>
