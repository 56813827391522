import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "menu",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/CalendarView.vue"),
  },
  {
    path: "/booker",
    name: "booker",
    component: () => import("../views/BookerView.vue"),
  },
  {
    path: "/map",
    name: "map",
    component: () => import("../views/MapView.vue"),
  },
  {
    path: "/photofeed",
    name: "photofeed",
    component: () => import("../views/PhotoFeedView.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: ':id',
        name: 'photofeedsingle',
        component: () => import("../views/PhotoFeedSingle.vue"),
      },
      {
        // Para QR generado desde app firmas
        path: 'key/:key',
        name: 'photofeedkey',
        component: () => import("../views/PhotoFeedSingle.vue"),
      }
    ]
  },
  {
    path: "/favorites",
    name: "favorites",
    component: () => import("../views/FavoriteGalleryView.vue"),
  },
  {
    path: "/chroma",
    name: "chroma",
    component: () => import("../views/ChromaKeyView.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/TermsAndConditions.vue"),
  },
  {
    path: "/inactivity",
    name: "inactivity",
    component: () => import("../views/InactivityScreen.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/UserLogin"),
    beforeEnter(to, from, next){
      // Deshabilitado si está logueado
      if(store.state.user && store.state.user.profile.id){
        return;
      }else{
        next()
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => undefined,
    beforeEnter(){
      // Deshabilitado si está logueado
      console.log("logout")
      localStorage.clear()
      router.push("/")
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/UserSignup"),
    beforeEnter(to, from, next){
      // Deshabilitado si está logueado
      if(store.state.user && store.state.user.profile.id){
        return;
      }else{
        next()
      }
    }
  },
  {
    path: "/spaces",
    name: "spaces",
    component: () =>
      import(/* webpackChunkName: "screens" */ "../views/SpacesView.vue"),
  },
  {
    path: "/duplicate",
    name: "duplicate",
    component: () => import("../views/DuplicateConnection"),
  },
  {
    path: "/duplicatescreen",
    name: "duplicatescreen",
    component: () => import("../views/DuplicateScreen")
  },
  {
    path: "/spaces/:slug",
    name: "spaceScreen",
    component: () =>
      import(/* webpackChunkName: "screens" */ "../views/SpaceScreenView.vue"),
  },
  {
    path: "/qr/:slug",
    name: "printQR",
    component: () =>
      import(/* webpackChunkName: "screens" */ "../views/PrintQR.vue"),
  },
  {
    path: "/:slug",
    name: "spaceController",
    component: () => import("../views/SpaceControllerView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", {to, from});
  if(store.hasModule('user')){
    const goingto = to.name == "spaceController" ? to.path.replace("/", "") : to.name;
    console.log(goingto)
    store.commit("user/SetSpace", {space: goingto})
  }
  next()
});

export default router;
