module.exports = {
    defaultLanguage: 'es',
    scssVariables: './src/styles/bago2025',
    profileColor: {saturation: 90, brightness: 100},
    favicon: '/bagofavicon.ico',
    defaultPassword: 'default',
    randomNickname:  () => { return RandomNickname() },
}

const RandomNickname = () => {
    const opc = ["Gonza", "Tomás", "Nico", "Juan", "Pablo", "Fede", "Mati", "Julieta", "Mica", "Sofia", "Camila", "Florencia", "Laura", "Vero", "Verónica"]
    return opc[Math.floor(Math.random() * opc.length)]
}