<template>
  <div class="is-flex is-justify-content-center">
    <img class="logo" :src="src" alt="App Logo" />
  </div>
</template>

<script>
export default {
  computed: {
    src() {
      if (!this.$store.state.setup.appLogo) {
        return "/douob-logo.png"
      }
      return this.$store.state.setup.appLogo.sizes.mobile.url || this.$store.state.setup.appLogo.url
    }
  }
};
</script>
