const DOUOB_options         = require("./styles/douob/options")
const DOUOB_2_options       = require("./styles/douob2/options")
const FYPF2022_options      = require("./styles/fypf2022/options")
const LEHMANN_options       = require("./styles/lehmann/options")
const SGPSEEDCARE_options   = require("./styles/sgpseedcare/options")
const BAGO2023_options      = require("./styles/bago2023/options")
const BAGO2025_options      = require("./styles/bago2025/options")
const SYNFUN2023_options    = require("./styles/synfun2023/options")
const ADAMA2023_options     = require("./styles/adama2023/options")
const SYNISF2023_options    = require("./styles/synisf2023/options")
const SYNPLAY_ES_options    = require("./styles/SYNPLAY_ES/options")
const WAVE2023_options      = require("./styles/wave2023/options")
const RAFFO2024_options     = require("./styles/raffo2024/options")
const SYNSTORESARG_options  = require("./styles/SYNSTORESARG/options")

const options = {
    'DOUOB': DOUOB_options,
    'DOUOB_2': DOUOB_2_options,
    'SGPSEEDCARE': SGPSEEDCARE_options,
    'SYNFIELDVISIT': SGPSEEDCARE_options, // Falta crear su propio setting
    'FYPF2022': FYPF2022_options,
    'LEHMANN': LEHMANN_options,
    'BAGO2023': BAGO2023_options,
    'BAGO2025': BAGO2025_options,
    'SYNFUN2023': SYNFUN2023_options,
    'ADAMA2023' : ADAMA2023_options,
    'SYNISF2023' : SYNISF2023_options,
    'SYNPLAY_ES' : SYNPLAY_ES_options,
    'WAVE2023' : WAVE2023_options,
    'RAFFO2024' : RAFFO2024_options,
    'SYNSTORESARG' : SYNSTORESARG_options,
}

module.exports = options[process.env.VUE_APP_PRODUCT_THEME]