<template>
    <b-modal v-model="$store.state.event.showModeratorQuestionBox" has-modal-card :can-cancel="true">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title is-size-5">Preguntas Al Orador</p>
            </header>
            <section class="modal-card-body has-background-white-ter overflow-scroll">
                <ModeratorQuestionBox />
            </section>
            </div>
        </b-modal>
</template>


<script>
import ModeratorQuestionBox from './ModeratorQuestionBox.vue';

export default {
    components: {
        ModeratorQuestionBox,
    },
    data() {
        return {
            show: true,
        };
    },
}
</script>