// import Vue from "vue";
import axios from "axios";
import qs from "qs";
import store from "@/store/index";

const api = `${process.env.VUE_APP_API_BASE}/api`;

const ENDPOINTS = {
  calendar: { url: "events", stateKey: "events" },
  maps: { url: "maps", stateKey: "maps" },
  booker: { url: "bookingspots", stateKey: "booker" },
};

const DEPTH = 3;
const LIMIT = 999;

export default {
  namespaced: true,
  state: () => ({
    events: null,
    eventsTab: null,
    maps: null,
    booker: null,
    myReservations: [],
    showModeratorQuestionBox: false,
    photofeed: null,
  }),
  mutations: {
    ToggleModeratorQuestionBox(state) {
      state.showModeratorQuestionBox = !state.showModeratorQuestionBox;
    },
  },
  actions: {
    async LoadPhotofeed(ctx){
      if (ctx.state.photofeed?.length) return;
      try{
        console.log("📸 Loading photofeed");
        // Define both fetch requests
        const res = await axios.get(`${process.env.VUE_APP_API_BASE}/api/photofeed?limit=0`);
        ctx.state.photofeed = res.data.docs;
      }catch(error){
        console.error("Error fetching photofeed:", error);
      }
    },
    async AgregarMediaPhotofeed(ctx, media){
      try{
        ctx.state.photofeed.unshift(media);
        // remove duplicate
        ctx.state.photofeed = ctx.state.photofeed.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.id === item.id
          ))
        );
      }catch(error){
        console.error("Error agregando media al photofeed:", error);
      }
    },
    async RemoveFromPhotofeed(ctx, imageId){
      ctx.state.photofeed = ctx.state.photofeed.filter(item => item.id !== imageId);
    },
    async LoadCalendarEvents(ctx) {
      if (ctx.state.events?.length) return;
    
      // Define both fetch requests
      const eventsRequest = axios.get(`${process.env.VUE_APP_API_BASE}/api/events?limit=999`);
      const eventTabRequest = axios.get(`${process.env.VUE_APP_API_BASE}/api/globals/eventstab`); // Replace with your actual endpoint
    
      // Use Promise.all to run both requests simultaneously
      return Promise.all([eventsRequest, eventTabRequest])
        .then(([eventsResponse, eventTabResponse]) => {
          console.log("Calendar Events", eventsResponse.data.docs);
          console.log("Another Data", eventTabResponse.data); // Log or process the second response
    
          // Update the state with the fetched data
          ctx.state.events = eventsResponse.data.docs;
          ctx.state.eventsTab = eventTabResponse.data; // Update the state with the second response data
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async GetRawData({ state }, key) {
      const event = store.state.setup.event;

      console.log("event", event[key]);

      const endpoint = ENDPOINTS[key];
      if (endpoint && event[key]?.enabled) {
        console.log("🍖 Getting raw data for", key);

        try {
          const res = await axios.get(
            `${api}/${endpoint.url}?depth=${DEPTH}&limit=${LIMIT}`
          );
          state[endpoint.stateKey] = res.data.docs;
          return { success: true };
        } catch (error) {
          return { status: false, error };
        }
      } else {
        return {
          success: false,
          error: "Endpoint not found or event not enabled",
        };
      }
    },
    async GetMyReservations({ state }) {
      const token = store.state.user.token;
      const res = await axios.get(
        `${api}/reservations?depth=0&where[user][equals]=${store.state.user.profile.id}`,
        {
          headers: {
            Authorization: "JWT " + token,
          },
        }
      );

      state.myReservations = res.data.docs;
    },
    async GetReservations(ctx, timeSlotId) {
      console.log("Getting reservations for:", timeSlotId);

      const stringifiedQuery = qs.stringify(
        {
          depth: 1,
          where: {
            timeSlotId: {
              equals: timeSlotId,
            },
          },
          limit: 0,
        },
        { addQueryPrefix: true }
      );

      let query = `${api}/reservations/${stringifiedQuery}`;

      console.log("Query:", query);

      const res = await axios.get(query);

      console.log("Reservations:", res.data.docs);

      let users = [];

      res.data.docs.forEach((doc) => {
        const user = {
          id: doc.user.id,
          name: doc.user.username,
        };
        if (doc.user.id === store.state.user.profile.id) {
          
            if (doc.guests) {
            doc.guests.forEach((guest) => {
              guest.isMine = true;
              users.unshift(guest);
            });
            }

          users.unshift(user);

        } else {
          users.push(user);
          if (doc.guests) {
            doc.guests.forEach((guest) => {
              guest.isMine = false;
              users.push(guest);
            });
          }
        }
      });

      return users;
    },
    async ReservateSlot({ state }, data) {


      const stringifiedQuery = qs.stringify(
        {
          data: JSON.parse(JSON.stringify(data)),
        },
        { addQueryPrefix: true }
      );

      const token = store.state.user.token;

      try {
        const res = await axios.put(
          `${api}/reservations/${data.timeSlotId}${stringifiedQuery}`,
          {
            headers: {
              Authorization: "JWT " + token,
            },
          }
        );

        if (res.status !== 200) {
          return { success: false, error: res.data };
        }

        const reservation = res.data.reservation;

        if (!state.myReservations.some((r) => r.id === reservation.id)) {
          state.myReservations.push(reservation);
        }

        const spot = state.booker.find(
          (spot) => spot.id === reservation.spotId
        );
        const court = spot.courts.find(
          (court) => court.id === reservation.courtId
        );
        const timeSlot = court.timeSlots.find(
          (timeSlot) => timeSlot.id === reservation.timeSlotId
        );

        timeSlot.reservations = res.data.reservations;

        return { success: true, data: res.data };
      } catch (error) {
        console.error("Error reservating slot:", error);
        return { success: false, error };
      }
    },
    async RemoveReservation({ state }, data) {
      console.log("Removing data:", data);

      const stringifiedQuery = qs.stringify(
        {
          data: JSON.parse(JSON.stringify(data)),
        },
        { addQueryPrefix: true }
      );

      const token = store.state.user.token;

      try {
        const res = await axios.delete(
          `${api}/reservations/${data.id}${stringifiedQuery}`,
          {
            headers: {
              Authorization: "JWT " + token,
            },
          }
        );

        console.log("* Deleted", res)

        state.myReservations = state.myReservations.filter(
          (reservation) => reservation.id !== data.id
        );

        const reservation = res.data;
        const spot = state.booker.find(
          (spot) => spot.id === reservation.spotId
        );
        const court = spot.courts.find(
          (court) => court.id === reservation.courtId
        );
        const timeSlot = court.timeSlots.find(
          (timeSlot) => timeSlot.id === reservation.timeSlotId
        );
        timeSlot.reservations = res.data.reservations;

        return { success: true, data: res.data };
      } catch (error) {
        return { success: false, error: error.response.data };
      }
    },
  },
};
