/* eslint-disable no-unused-vars */
import {Howl, Howler} from 'howler';
import _ from "lodash";

export default {
    install(Vue) {
        var SOUNDS = {};
        var SOUNDKEYS = []; 
        var PlayDefaults = {
            loop: false,
            fadein: false,
        }
        var StopDefaults = {
            fadeout: true,
        }
        
        Vue.prototype.$sfxLoad = function(soundsToLoad = this.$store.state.setup.sounds, filter){

            const filteredSounds = filter
                ? Object.fromEntries(
                    Object.entries(soundsToLoad).filter(([key]) => filter.includes(key))
                )
                : { ...soundsToLoad }

            var newKeys = Object.keys(filteredSounds)
            let volume = this.$store.getters["space/config"] ? this.$store.getters["space/config"].space.volume / 100 : 1;
            
            console.log("🎹 Loading", newKeys.length,"sounds")
            console.log("filteredSounds", filteredSounds) // ADDED

            newKeys.forEach((soundKey) => {
                const data = filteredSounds[soundKey]
                if(data == null) return

                let sobreescribiendo = typeof(SOUNDS[soundKey]) == "object"

                if(sobreescribiendo){
                    
                    if(SOUNDS[soundKey]._src != data.url) {
                        console.log("🎹 Overriding sound",soundKey)

                        SOUNDS[soundKey].unload()
                        SOUNDS[soundKey] = new Howl({
                            src: [data.url]
                        });
                    } 

                }else{
                    SOUNDS[soundKey] = new Howl({
                        src: [data.url]
                    });                  
                }
                SOUNDS[soundKey].isPlaying = false
                SOUNDS[soundKey].on('stop', function(){
                    if(!SOUNDS[soundKey]._loop){
                        SOUNDS[soundKey].isPlaying = false
                    }
                })
                
                SOUNDKEYS = _.union(SOUNDKEYS, newKeys);
            })

            Howler.volume(volume)
        }

        Vue.prototype.$sfxPlay = function(key, _settings){
            try{
                if(!SOUNDKEYS.includes(key)){
                    console.warn("🔊 Sonido", key, "no existe o no cargado en backend")
                    return;
                }
                if(typeof SOUNDS[key] == 'undefined') return;
                let settings = {...PlayDefaults, ..._settings}
                console.log("🔊 playing", key, "@ volume", Howler.volume())
                SOUNDS[key].loop(settings.loop)
                SOUNDS[key].volume(Howler.volume())
                SOUNDS[key].seek(0)
                SOUNDS[key].play()
                SOUNDS[key].isPlaying = true
                // console.log("playing", SOUNDS[key].isPlaying)
                if(settings.fadein){
                    SOUNDS[key].fade(0, Howler.volume(), 700)
                }
            }catch(e){
                console.warn("❗️ Error on sfxPlay", e)
            }
        }

        Vue.prototype.$sfxStop = function(key, _settings){
            try{
                if(!SOUNDKEYS.includes(key)){
                    console.warn("🔇 Sonido", key, "no existe o no cargado en backend")
                    return;
                }
                if(typeof SOUNDS[key] == 'undefined') return;
                let settings = {...StopDefaults, ..._settings}
                console.log("🔇 stopping", key)
                if(settings.fadeout){
                    SOUNDS[key].fade(Howler.volume(), 0, 1000)
                    SOUNDS[key].once('fade', function(){
                        SOUNDS[key].pause()
                    });
                }else{
                    SOUNDS[key].pause()
                }
            }catch(e){
                console.warn("❗️ Error on sfxStop", e)
            }
        }

        Vue.prototype.$sfxChangeVolume = function(volume){
            Howler.volume(volume);
            console.log("🔊 Changing Howler volume to", volume)
        }
    },
};