<template>
  <div class="content " v-html="html">
  </div>
</template>

<script>
import escapeHtml from 'escape-html'
import { Text } from 'slate'

export default {
  data() {
    return {
      html: null,
    }
  },
  props: {
    text: {
      type: Array,
      required: true,
    },
  },
  methods: {
    Serialize(node) {
      let out = "";
      if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.bold) {
          string = `<strong>${string}</strong>`
        }
        if (node.underline) {
          string = `<u>${string}</u>`
        }
        if (node.italic) {
          string = `<i>${string}</i>`
        }
        return string
      }

      if (node.length == undefined) {
        out += this.ParseElement(node)
      } else {
        node.forEach(el => {
          out += this.ParseElement(el)
        })
      }
      return out;
    },

    ParseElement(element) {
      const children = element.children.map(n => this.Serialize(n)).join('')
      switch (element.type) {
        case 'h1':
          return `<h1>${children}</h1>`
        case 'h2':
          return `<h2>${children}</h2>`
        case 'h3':
          return `<h3>${children}</h3>`
        case 'h4':
          return `<h4>${children}</h4>`
        case 'h5':
          return `<h5>${children}</h5>`
        case 'h6':
          return `<h6>${children}</h6>`
        case 'li':
          return `<li>${children}</li>`
        case 'ul':
          return `<ul>${children}</ul>`
        case 'ol':
          return `<ol>${children}</ol>`
        case 'quote':
          return `<blockquote><p>${children}</p></blockquote>`
        case 'paragraph':
          return `<p>${children}</p>`
        case 'link':
          return `<a href="${escapeHtml(element.url)}" target="${element.newTab ? '_blank' : '_self'}">${children}</a>`
        case 'hr':
          return `<hr>`
        default:
          return `<p>${children}</p>`
      }
    }
  },

  mounted() {
    this.html = this.Serialize(this.text);
  }
};
</script>