<template>
    <!-- TABS (solo admin) -->
    <b-tabs v-model="activeTab" :class="{ 'no-labels': !ShowAdminUi }" expanded>
        <b-tab-item :label="$t('exp_textbox.send')">
            <section class="px-5">
            <p class="has-text-weight-bold my-3">{{ $t('exp_textbox.writeYourQuestion') }}</p>
            <b-field class="mb-0">  
                <b-input maxlength="140" type="textarea" v-model="questionText"></b-input>
            </b-field>
            <div class="send-buttons">
                <b-button v-if="ShowAdminUi" :label="$t('exp_textbox.sendRandom')" type="is-light"
                    @click="SendFake()" />
                <b-button :label="$t('exp_textbox.send')" type="is-primary" expanded
                    :disabled="!allowedToSubmitQuestion" @click="SendQuestion()" :loading="loading" />
            </div>
            </section>
        </b-tab-item>
        <b-tab-item v-if="ShowAdminUi" :label="$t('exp_textbox.moderate')">
            <section class="px-5">
                <ModeratorQuestionAdmin />
            </section>
        </b-tab-item>
    </b-tabs>
</template>

<script>
import axios from "axios";
import { hsv2hex } from "color-functions";
import ModeratorQuestionAdmin from "./ModeratorQuestionAdmin.vue";
// import { formatTimeAgo } from "../utils";
const whitelabelConfig = require('@/whitelabel.config');

export default {
    components: {
        ModeratorQuestionAdmin,
    },
    data() {
        return {
            questionText: "",
            activeTab: 0,
            loading: false,
        };
    },
    methods: {
        async SendQuestion() {
            // Todo chequear que tenga caracteres minimos
            // this.$store.dispatch('user/SendReaction', {reaction: this.questionText, type:'text', category: this.questionCategory})
            try {
                this.loading = true;
                const res = await axios.post(process.env.VUE_APP_API_BASE + "/api/moderatorQuestions", {
                    question: this.questionText,
                    createdBy: this.$store.state.user.profile.id,
                })
                if (res.status == 201 || res.status == 200) {
                    this.$buefy.notification.open({
                        type: "is-primary",
                        message: this.$t('exp_textbox.questionSent')
                    });
                }
                this.questionText = ""
            } catch (e) {
                console.error(e)
                this.$buefy.notification.open({
                    type: "is-danger",
                    message: this.$t('exp_textbox.errorSendingQuestion')
                });
            } finally {
                this.loading = false;
            }

            this.$sfxPlay('positivenotification')

            // Pensar si queremos evitar que spameen? Tipo un cooldown antes de poder enviar
        },
        SendFake() {
            // Send random fake text reaction
            console.log('sending fake random text reaction')

            let hueVal = Math.round(Math.random() * 360)
            let color = hsv2hex(
                hueVal,
                this.$store.getters["user/ColorSaturation"],
                this.$store.getters["user/ColorBrightness"]
            ).substring(1);
            // let category = this.questionCategory || this.options.categories[Math.round(Math.random() * 99999) % this.options.categories.length]?.name;
            this.$socket.client.emit('chromaGameMessage', {
                type: 'PreguntaFake',
                id: Date.now(),
                createdBy: {
                    username: whitelabelConfig.randomNickname(),
                    color: color,
                    avatar: Math.floor(Math.random() * (this.$store.state.avatars.length - 2) + 1)
                },
            })
            this.$buefy.notification.open({
                type: "is-primary",
                message: this.$t('exp_textbox.questionSent')
            });
        },
    },
    computed: {
        allowedToSubmitQuestion() {
            if (this.questionText.length < 4) return false  // Minimo 4 caracteres
            return true;
        },
        showModeratorQuestionBox() {
            return this.$store.state.event.showModeratorQuestionBox;
        },
        ShowAdminUi() {
            return this.$store.getters['user/hasAdminAuthority'] && this.$store.hasModule('admin')
        }
    },
    watch: {
        showModeratorQuestionBox: {
            immediate: true,
            handler(val) {
                this.show = val;
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.send-buttons {
    display: flex;
    gap: 1em;
}

.overflow-scroll {
    overflow-y: scroll;
}
</style>