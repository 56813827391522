<template>
    <div v-if="moderationEntries.length == 0">
        <div class="empty-state">
            <p>No hay preguntas para moderar</p>
        </div>
    </div>
    <div v-else>
        <div class="column">
            <b-button
                :label="$t('exp_textbox.removeCurrent')"
                @click="QuitarPreguntaActual()"
                type="is-danger"
                size="is-small"
                expanded/>
        </div>
        <b-table :data="moderationEntries" :striped="true" :hoverable="true" :mobile-cards="true"
            :paginated="moderationEntries.length > 12" :per-page="12" :row-class="(row, index) => `state-${row.state}`">
            <!-- :row-class="(row, index) => row.shownOnScreen && 'shownOnScreen'" -->

            <b-table-column field="text" label="Pregunta" v-slot="props">
                {{ props.row.question }}
            </b-table-column>

            <b-table-column field="username" label="Usuario" class="td-user" v-slot="props">
                <div class="td-user">{{ props.row.externalUser ? props.row.externalUser.name: props.row.createdBy.username }}</div>
            </b-table-column>

            <b-table-column field="sent" label="Sent" v-slot="props">
                <!-- TODO !! Revisar no anda -->
                <div class="td-sent">{{ timeAgo(props.row.createdAt) }}</div>
            </b-table-column>

            <b-table-column field="actions" v-slot="props">
                <div class="actions">
                    <b-button type="is-danger" size="is-small" expanded @click="RejectQuestion(props.row)"><d-icon
                            icon="FaTrash" size="is-small" /></b-button>
                    <b-button type="is-success" size="is-small" expanded @click="EditBeforeSend(props.row)"><d-icon
                            icon="FaShare" size="is-small" /></b-button>
                </div>
            </b-table-column>

        </b-table>
    </div>
</template>


<script>
import axios from "axios";
import { formatTimeAgo } from "@/utils/utils";

export default {
    components: {
    },
    data() {
        return {
            moderationEntries: [],
            loading: false,
        };
    },
    sockets: {
        newModeratorQuestion(data){
            const {question} = data;
            console.log("newModeratorQuestion", question)
            this.moderationEntries.unshift(question);
        },
    },
    methods: {
        QuitarPreguntaActual(){
            console.log("QuitarPreguntaActual")
            this.$socket.client.emit('chromaGameMessage', {
                type: 'QuitarPreguntaActual',
            });
        },
        timeAgo(time) {
            return formatTimeAgo(time, this.$i18n.locale)
        },
        async RetrieveQuestions() {
            try {
                this.loading = true;
                const res = await axios.get(`${process.env.VUE_APP_API_BASE}/api/moderatorQuestions/?limit=999&where[state][equals]=pending`)
                this.moderationEntries = res.data.docs
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false;
            }
        },
        async RejectQuestion(question){
            try {
                console.log("Rejecting question", question)
                this.loading = true;
                const res = await axios.patch(`${process.env.VUE_APP_API_BASE}/api/moderatorQuestions/${question.id}`, {
                    state: "rejected"
                })
                if(res.status == 200){
                    this.$buefy.toast.open({
                        message: "Pregunta rechazada",
                        type: "is-danger"
                    })
                    this.moderationEntries = this.moderationEntries.filter(q => q.id != question.id)
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false;
            }
        },
        EditBeforeSend(question){
        console.log("edit", question)
        this.$buefy.dialog.prompt({
            message: 'Revisá y corregí la pregunta antes de aceptarla',
            inputAttrs: {
                type: 'text',
                placeholder: 'My message is...',
                value: question.question
            },
            confirmText: 'Send',
            trapFocus: true,
            closeOnConfirm: true,
            onConfirm: async (value, {close}) => {
              console.log(value)
              // LE DIGO A PAYLOAD QUE LO MARQUE COMO YA ENVIADO
              await axios.patch(`${process.env.VUE_APP_API_BASE}/api/moderatorQuestions/${question.id}`, {
                state: "approved",
                question: value
              },
              {
                headers: {
                  Authorization: "JWT " + this.$store.state.user.token,
                },
              });
              // MODIFICO LA INSTANCIA LOCAL
              const item = this.moderationEntries.find(x => x.id == question.id)
              item.question = value
              item.state = "approved"
              close()
              this.$buefy.toast.open('Reaction sent to screen')
            }
        })
    },
    },
    async mounted() {
        this.RetrieveQuestions()
    }
}
</script>

<style lang="scss">
td:last-of-type::before {
    content: unset !important;
}

.tab-content {
    padding-inline: 0px !important;
}

.no-labels {
    nav {
        display: none;
    }
}

.actions {
    display: flex;
    width: 100%;
    gap: 10px;
}

.state-approved {
    opacity: .3;
}

.pagination-previous>.icon::after {
    content: "<";
}

.pagination-next>.icon::after {
    content: ">";
}

.questions-tools {

    display: flex;
    justify-content: flex-end;

    .columns {
        max-width: 400px;

        .column {
            display: flex;
            align-items: flex-end;
        }
    }

}

.td-user {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    max-width: 85px;
}

.td-sent {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: fit-content;
}
</style>